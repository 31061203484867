/* grid.css */
.grid-demo div[class*='col'] {
  background: #fff;
  text-align: left;
  color: #000;
  border: 1px solid #ddd;
  padding: 5px;
  /* margin-bottom: 15px; */
  font-size: 15px;
}
.grid-demo2 div[class*='col2'] {
  background: #fff;
  text-align: left;
  color: #000;
  border: 1px solid #ddd;
  padding: 5px;
  /* margin-bottom: 15px; */
  font-size: 15px;
}
.grid-resizable-demo {
  --f7-grid-row-gap: 16px;
}
.grid-resizable-demo div[class*='col'] {
  margin-bottom: 0;
}
.grid-resizable-demo .demo-col-center-content {
  display: flex;
  align-items: center;
  justify-content: center;
}