/* iOS Cordova Tweak */
.device-cordova.device-ios {
  height: 100vh;
}

/* Custom color theme properties */
:root {
  --f7-theme-color: #808cce;
  --f7-theme-color-rgb: 48, 135, 49;
  --f7-theme-color-shade: #255669;
  --f7-theme-color-tint: #4b579e;
}

/* Invert navigation bars to fill style */
:root,
:root.theme-dark,
:root .theme-dark {
  --f7-bars-bg-color: var(--f7-theme-color);
  --f7-bars-bg-color-rgb: var(--f7-theme-color-rgb);
  --f7-bars-translucent-opacity: 0.9;
  --f7-bars-text-color: #fff;
  --f7-bars-link-color: #fff;
  --f7-navbar-subtitle-text-color: rgba(255,255,255,0.85);
  --f7-bars-border-color: transparent;
  --f7-tabbar-link-active-color: #fff;
  --f7-tabbar-link-inactive-color: rgba(255,255,255,0.54);
  --f7-sheet-border-color: transparent;
  --f7-tabbar-link-active-border-color: #fff;
}
.appbar,
.navbar,
.toolbar,
.subnavbar,
.calendar-header,
.calendar-footer {
  --f7-touch-ripple-color: var(--f7-touch-ripple-white);
  --f7-link-highlight-color: var(--f7-link-highlight-white);
  --f7-link-touch-ripple-color: var(--f7-touch-ripple-white);
  --f7-button-text-color: #fff;
  --f7-button-pressed-bg-color: rgba(255,255,255,0.1);
}
.navbar-large-transparent,
.navbar-large.navbar-transparent {
  --f7-navbar-large-title-text-color: #000;

  --r: 48;
  --g: 135;
  --b: 49;
  --progress: var(--f7-navbar-large-collapse-progress);
  --f7-bars-link-color: rgb(
    calc(var(--r) + (255 - var(--r)) * var(--progress)),
    calc(var(--g) + (255 - var(--g)) * var(--progress)),
    calc(var(--b) + (255 - var(--b)) * var(--progress))
  );
}
.theme-dark .navbar-large-transparent,
.theme-dark .navbar-large.navbar-transparent {
  --f7-navbar-large-title-text-color: #fff;
}

/* Left Panel right border when it is visible by breakpoint */
.panel-left.panel-in-breakpoint:before {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 1px;
  background: rgba(0,0,0,0.1);
  content: '';
  z-index: 6000;
}

/* Hide navbar link which opens left panel when it is visible by breakpoint */
.panel-left.panel-in-breakpoint ~ .view .navbar .panel-open[data-panel="left"] {
  display: none;
}

/*
  Extra borders for main view and left panel for iOS theme when it behaves as panel (before breakpoint size)
*/
.ios .panel-left:not(.panel-in-breakpoint).panel-in ~ .view-main:before,
.ios .panel-left:not(.panel-in-breakpoint).panel-closing ~ .view-main:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 1px;
  background: rgba(0,0,0,0.1);
  content: '';
  z-index: 6000;
}

:root,
:root.theme-dark,
:root .theme-dark {
  --f7-bars-bg-color:linear-gradient(0deg, #3E3A39, #595757 50%, #3E3A39);
}

:root .theme-dark {
  --f7-list-bg-color:linear-gradient(0deg, #3E3A39, #595757 50%, #3E3A39);
  --f7-page-bg-color:linear-gradient(0deg, #3E3A39, #595757 50%, #3E3A39);
}

.md .theme-dark, .md.theme-dark {
  --f7-page-bg-color: #306b9c;
}

.ios-translucent-bars, .navbar-bg {
  background:linear-gradient(0deg, #3E3A39, #595757 50%, #3E3A39) !important;
}

.ios-translucent-bars, .toolbar {
  background:linear-gradient(0deg, #3E3A39, #595757 50%, #3E3A39) !important;
}

.swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction{
  top: 0px;
  bottom: unset;
  padding-top: 0px ;
}

.swiper-wrapper {
margin-top:25px ;
}

.list ul ul {
  padding-left: 0px;
}

.card {
  --f7-card-margin-horizontal: 0px;
  --f7-safe-area-left: 0px;
  --f7-safe-area-right: 0px;
}

.block {
  --f7-block-padding-horizontal: 0px;
  --f7-safe-area-left: 10px;
  --f7-safe-area-right: 10px;
}

.data-table thead th, .data-table thead td {
  --f7-table-head-font-size: 16px;
}
.data-table thead th:not(.sortable-cell-active), .data-table thead td:not(.sortable-cell-active) {
  --f7-table-head-text-color: #000000;
}

.card .data-table th　.table-width {
  width:20px;
}

.treeview-item-content:first-child {
  --f7-treeview-toggle-size:6px;
}

.list {
  margin: 0px;
}

.list .item-content {
  padding-left: 0px;
}

.list .item-inner {
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}

.check-method > .item-content > .item-inner > .item-input-wrap{
	// min-height: 260px; /* 高さ */
	height: 260px; /* 高さ */
}

.check-method > div > div > div > textarea {
	height: 100%; /* 高さ */
}

.check-method-popup > .item-content > .item-inner > .item-input-wrap{
	// min-height: 250px; /* 高さ */
	height: 250px; /* 高さ */
}

.check-method-popup > div > div > div > textarea {
	height: 100%; /* 高さ */
}

.haccp-role-invisible{
  display: none;
}

#framework7-root.haccp-role-system .haccp-role-invisible.haccp-role-system,
#framework7-root.haccp-role-admin .haccp-role-invisible.haccp-role-admin,
#framework7-root.haccp-role-user .haccp-role-invisible.haccp-role-user,
#framework7-root.haccp-role-pre-regist .haccp-role-invisible.haccp-role-pre-regist,
#framework7-root.haccp-role-guest .haccp-role-invisible.haccp-role-guest {
  display: block;
}

.data-table th, .data-table td {
  --f7-table-cell-padding-left: 6px;
  --f7-table-cell-padding-right: 6px;
}
.category-title {
  white-space: pre-line !important;
  min-width: 140px !important;
}

.table-daily_check{
  min-width: 100px !important;
}

.table-memo{
  min-width: 300px !important;
}

.table-admin-memo{
  min-width: 90px !important;
}

.delete-button{
  color:red !important;
}

.menu-title{
  min-height: 100px !important;
  width: 32px !important;
}

.menu-title > span{
  writing-mode: vertical-rl !important;
  text-orientation: upright !important;
}

.menu {
  white-space: pre-line !important;
}

.item-title {
  white-space: pre-line !important;
  min-width: 100px !important;
}

.list-button {
  padding: 0px !important;
}

.block {
  margin: var(--f7-block-margin-vertical) 10px;
}

.no-padding {
  padding: 0 !important;
  --f7-safe-area-left: 0px !important;
  --f7-safe-area-right: 0px !important;
}

    /* トップページ */
.side-menu,
.top-title,
/* 毎日の記録 */
.record-title,
/* 計画 */
.plan-title,
/* 管理 */
.manegement-title > a,
/* 計画テンプレート */
.template-title,
/* マスタ管理 */
.mst-title,
/* プロファイル */
.profile-title > a
{
  font-weight: bold;
  background:linear-gradient(0deg, #3E3A39, #595757 50%, #3E3A39) !important;
}

.manegement-title > a > div > div > div.item-title,
.profile-title > a > div > div > div.item-title
{
  font-weight: bold;
}

.terms-service-title > a
{
  font-weight: bold;
  background:#3E3A39;
  // background:#595757
  // background:#3E3A39
}


/* お知らせ */
.notice{
  color:#000000;
  background:rgb(255, 255, 255);
}

.notice > a > div > div.item-inner::before
{
  color:#000000 !important;
}

.general > a > div > div.item-inner::before,
.important > a > div > div.item-inner::before,
.manegement-title > a > div > div.item-inner::before,
.profile-title > a > div > div.item-inner::before,
.mst > a > div > div.item-inner::before
{
  color:#ffffff !important;
}

/* 各マスタ */
.mst, .mst > div.navbar-bg{
  background:rgb(214, 70, 13);
}

.mst > a > div > div > div.item-title{
  font-weight: bold;
}

/* 各一般 */
.general, .general > div.navbar-bg{
  background:#0075C2 !important;
}

.general > a > div > div > div.item-title{
  font-weight: bold;
}

/* 各重要 */
.important, .important > div.navbar-bg{
  background:#159600 !important;
}

.important > a > div > div > div.item-title{
  font-weight: bold;
}

.profile-button{
  color:#4b579e;
  background:rgb(255, 255, 255);
  font-weight: bold;
}

.title-size{
  margin-top: var(--f7-block-margin-vertical);
  margin-bottom: var(--f7-block-title-margin-bottom);
  font-weight: bold;
}

.navbar a.link{
  width: var(--f7-navbar-link-height, var(--f7-navbar-height)); 
}

.list input[type='email'][readonly],
.list input[type='password'][readonly]
{
  height: 30px;
}

.mst-list{
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.mst-list > div > .treeview > .treeview-item > .treeview-item-root,
.mst-list > div > .treeview > div > .treeview-item > .treeview-item-root {
    padding-left: 0px;
  padding-right: 0px;
}

.mst-list > div > .treeview > .treeview-item > .treeview-item-root > .treeview-item-content:first-child,
.mst-list > div > .treeview > div > .treeview-item > .treeview-item-root > .treeview-item-content:first-child {
    margin-left: 0px;
}

.mst-list > div > .treeview > .treeview-item > .treeview-item-root > .treeview-item-content > .treeview-item-label,
.mst-list > div > .treeview > div > .treeview-item > .treeview-item-root > .treeview-item-content > .treeview-item-label{
    font-size: 14px;
}

.resizeimage img { width: 100%; }

.p-top > ul{
  padding-top: 8px;
}


.font13,
.font13 > div > div > div > input,
.font13 > div > div > div > select
{
  font-size: 13px !important;
}

.font-bold{
  font-weight: bold !important;
}

.width30 {
  white-space: nowrap !important;
  min-width: 30px !important;
}

.width120 {
  white-space: nowrap !important;
  min-width: 120px !important;
}

.height24{
  height: 24px !important;
}

.height48{
  height: 48px !important;
}

.bottom-border-double{
  border-bottom: 4px double whitesmoke !important; /* ヘッダ行の下線 */
} 

.search-title > div > div > .item-title
{
  height: 20px !important;
}

.search-condition
{
  width: 300px !important;
}

.inline-block{
  display: inline-block;
}

.search-title > div > div > .item-input-wrap,
.search-title > div > div > div > input,
.search-title > div > div > div > .input-with-value
{
  min-height: 30px !important;
  height: 30px !important;
}

.search-title > div > .item-inner
{
  padding-top: 0px;
}

.search-title-input,
.search-title-input > div > div > div > input
{
  white-space: nowrap !important;
  max-width: 500px !important;
}

.search-title-date,
.search-title-date > div > div > div > input
{
  white-space: nowrap !important;
  width: 160px !important;
}

.search-title-select > div > div > div,
.search-title-select > div > div > div > select
{
  white-space: nowrap !important;
  width: 130px !important;
}

.search-data{
  margin-top: 0px !important;
}

#framework7-root.haccp-role-guest > .dialog > .dialog-inner{
  padding: 15px;
}

#framework7-root.haccp-role-guest > .dialog > .dialog-inner > .dialog-title,
#framework7-root.haccp-role-guest > .dialog > .dialog-inner > .dialog-text {
  font-size: 13px;
  color: black !important;
}

.accordion-list > div > ul > li > a > div > .item-inner {
  padding-left: 30px !important;
}

.accordion-list > div > ul {
  background: rgb(236, 231, 231) !important;
}

.daily-create-window > div > div > .item-title, .item-label{
  width: initial !important;
}

.daily-create-button{
  margin: 15px;
  padding: 0px;
}

.daily-create-button > .list-button{
  padding: 0px 0px 0px 0px !important;
}

.daily-create-window > div > .item-inner > .item-title{
  padding: 0px;
  margin: 0px;
}

.daily-create-window > div > .item-inner,
.daily-create-window > div > .item-inner > .item-input-wrap{
  padding: 0px;
  margin: 0px;
}

.daily-create-window > div > .item-inner {
  margin: 0px;
  padding: 0px;
}


.mst-list-item > a > div > div > .item-title{
  font-size: 14px;
}

.mst-list-item > a > div > .item-inner {
  padding-top: 5px;
}

.terms-service {
  margin: 10px 15px 0px 15px;
}

.terms-service > .item-content > .item-inner{
  display: flex;
  justify-content: center;
}

.terms-service > div > .item-inner::after {
  content: none;
}

.dialog {
  max-height: 80vh !important;
  overflow-y: scroll;
  text-align: initial;
}

.page-terms-service > p {
  font-size: 13px;
  color: black;
}

.terms-btn{
  padding-top: 50px;
}

.item-input-error-message{
  white-space: normal !important;
  position: initial !important;
}

.terms-checkbox .item-inner,
.checkboxMessage,
.checkboxErrorMessage{
  display: block;
}

.checkboxMessage{
  font-size: var(--f7-input-font-size);
  color: red !important;
}

.checkboxMessage > input{
  margin: 3px 8px 3px 4px;
}

.terms-checkbox-error-message > div > div {
  padding-top: 0px !important;
}

.terms-checkbox-error-message > div > div > div > .item-input-error-message {
  width: 34vh;
  padding-top: 0px;
  margin-top: 0px;
}

.terms-check > .item-content > .item-inner{
  justify-content: initial;
}

.terms-checkbox-title > div > div > .item-label{
  color: red !important;
  font-size: 13px !important;
  padding-top: 1em !important;
  height:100%!important;
  vertical-align: top !important;
}
.terms-checkbox-title > div > div > .item-input-wrap{
  width: 15px !important;
  height:100%!important;
  vertical-align: top !important;
}

.terms-checkbox-title > div > div > .item-input-wrap:after{
  background-color: white !important;
}

.user-withdrawal{
  margin-top: 30px !important;  
}

.user-withdrawal-msg{
  color: red !important;
  font-size: 14px;
}

#userWithdrawalCheckboxMessage{
  color: red !important;
}

.ctrl-list{
  margin-top: 0px !important;  
}

.ctrl-list > .card-header{
  min-height: 50px !important;
}

.ctrl-list > .card-header,
.ctrl-list > .card-content {
  padding-top: 0px !important;  
  padding-bottom: 0px !important;  
}

.dgc-1{
  margin-top: 15px;
}

.dgc-title > div > div{
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  font-size: 18px;
  color: red;
}


.blue-circle{
  display: inline-block;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  border: solid 1px blue;
  color: blue;
  font-weight: bold;
}

.red-circle{
  display: inline-block;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  border: solid 1px red;
  color: red;
  font-weight: bold;
}

.transparent-circle{
  display: inline-block;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  border: solid 1px rgba(0,0,0,0.0);
  font-weight: bold;
}

.hc-list-bk-g{
  background-color: #b7e8fc;
}

.btm-type1{
  max-width: 430px;
}

.button_hidden{
  font-weight: normal;
  // display: none;
}

.button_disp{
  font-weight: bold;
}
